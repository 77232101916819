<template>
    <v-row justify="center">
        <v-dialog
            v-show="dialog"
            persistent
            hide-overlay
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                    <span class="text-h5">注册</span>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: 'RegisterDialog',
        props: ['dialog'],
    }
</script>