<template>
    <Navbar />
    <v-app :style="{ background: $vuetify.theme.themes.dark.background}">
        <v-container id="home" class="bg-home" fluid>
            <v-row >
                <v-col cols="8">
                    <h3 class="white--text top left" color="red-darken-2">面包找房</h3>
                    <br />
                    <v-text-field
                        class="search left"
                        label="Enter address, MLS@"
                    >
                    </v-text-field>
                    <v-btn class="left" tile color="#A7121D" dark>
                       <div class="white-text">搜索</div> 
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <h1 class="top black-text" >租房买房就上面包找房</h1>
                    <br />
                    <h3 class="dark-red-text">租房、买卖二手房、楼花、二手交易一站式服务，应有尽有</h3>
                </v-col>
            </v-row>
        </v-container>
        <v-container id="sale">
            <br />
            <br />
            <v-col class="sale_content-tex" align="center" justify="center">
                <strong ><h2>在售推荐</h2></strong>
                <h2 class="dark-red-text"><strong>___________</strong></h2>
            </v-col>
            <br />
            <v-row no-gutters>
                <v-col
                    v-for="condo in condos"
                    :key="condo.mlNum"
                    cols="12"
                    sm="3"
                    >
                    <HomeSale :condo="condo"/>
                </v-col>
            </v-row>
            <v-col class="sale_content-tex" align="center" justify="center">
                <v-btn tile color="#A7121D" dark @click="this.$router.push('/maplist')">
                       <div class="white-text">查看更多</div> 
                </v-btn>
                <v-btn tile color="#A7121D" dark @click="this.$router.push('/formrental')">
                       <div class="white-text">Rental Form</div> 
                </v-btn>
            </v-col>
        </v-container>
        <v-footer
            class="bg-indigo-lighten-1 text-center d-flex flex-column"
            >
            <div>
                <v-btn
                    v-for="icon in icons"
                    :key="icon"
                    class="mx-4"
                    :icon="icon"
                    variant="text"
                    >
                </v-btn>
            </div>

            <!-- <div class="pt-0">
                Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui. Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </div> -->
            <v-divider></v-divider>
            <div>
                {{ new Date().getFullYear() }} — <strong>加拿大地产</strong>
            </div>
        </v-footer>
    </v-app>
</template>

<script>
    import Navbar from '@/components/Navbar'
    import HomeSale from '@/components/HomeSale'

    export default {
        name: 'Home',
        data() {
            return {
                condos:[
                    {
                            mlNum: 'C5625246',
                            addr: '115 Mcmahon Dr',
                            lpDol: '645,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/4.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/5.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/6.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/7.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/8.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'S5598774',
                            addr: '58 Lakeside Terr',
                            lpDol: '549,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5579580',
                            addr: '1 Avondale Ave',
                            lpDol: '788,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5624763',
                            addr: '455 Front St E',
                            lpDol: '599,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5626010',
                            addr: '15 Stafford St',
                            lpDol: '19,650',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5626433',
                            addr: '60 Tannery Rd',
                            lpDol: '759,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'W5568364',
                            addr: '320 Dixon Rd',
                            lpDol: '424,900',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'E5573103',
                            addr: '1359 Neilson Rd',
                            lpDol: '695,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/4.jpeg',
                                },
                            ]
                    }, 
                ],
                icons: [
                    'mdi-facebook',
                    'mdi-twitter',
                    'mdi-linkedin',
                    'mdi-instagram',
                ],
                cards: [
                    1,2,3,4
                ]
            }
        },
        components: {
            Navbar,
            HomeSale,
        },
    }
</script>

<style>
.sale_content-text {
    margin-top: 8px;
}
.dark-red-text {
    color: #A7121D;
}
.white-text {
    color: #ffffff;
}
.black-text {
    color: #181818
}
.footer-bg {
    background: linear-gradient(
        to right,
        #A7121D,
        #A7121D 50%,
        #A7121D 50%,
        #A7121D 50%,
    );
}
.bg-home {
  background: linear-gradient(
      180deg,
      rgba(246, 246, 246, 0.5) 1%,
      rgba(246, 246, 246, 0.274) 51.03%,
      rgba(248, 248, 248, 0.5) 100%
    ),
    url("../assets/toronto-bg.png");
  height: 100vh !important;
  background-size: cover;
}
.bg-sale {
  background: linear-gradient(
      180deg,
      rgba(246, 246, 246, 0.5) 1%,
      rgba(246, 246, 246, 0.274) 51.03%,
      rgba(248, 248, 248, 0.5) 100%
    ),
    url("../assets/home-sale-bg.png");
  height: 100vh !important;
  background-size: cover;
}
.bg2 {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 1%,
      rgba(0, 0, 0, 0.273638) 51.03%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../assets/toronto-bg.png");
  height: 100vh !important;
  background-size: cover;
}
.top {
    margin-top: 300px;
}
.left {
    margin-left: 180px;
}
.search {
    width: 600px;
    background: linear-gradient(
        to right,
        #ffffff,
        #ffffff 50%,
        #ffffff 50%,
        #ffffff 50%,
    );
}
.firstCard {
    width: 100%;
    height: 600px;
    background: linear-gradient(
        to right,
        #181818,
        #181818 50%,
        #111111 50%,
        #111111 50%,
    );
    text-align: center;
    padding: 2rem 2rem;
}
</style>