<template>
    <v-card
        :loading="loading"
        class="mx-auto my-12"
        max-width="230"
        >
        <v-carousel hide-delimiters show-arrows="hover" height="200px">
            <v-carousel-item
                cover
                v-for="(item,i) in house.items "
                :key="i"
                :src="item"
                >
            </v-carousel-item>
        </v-carousel>

        <v-card-title>{{ house.addr }}</v-card-title>

        <v-card-text>
            <v-row
                align="center"
                class="mx-0"
                >
                <div>MLS@</div> {{ house.mlNum }}
            </v-row>
            <v-row
                align="center"
                class="mx-0 text-subtitle-1"
                >
                <p color="#111000">MLS@</p> {{ house.mlNum }}
            </v-row>
            <br />
            <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: ['house', 'loading'],
        data() {
            return {
            }
        },
    }
</script>

<style>
.maplist-mls {
    text-decoration-color: #181818;
}
</style>