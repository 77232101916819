<template>
    <Navbar />
    <h1>楼花测试页面</h1>
</template>

<script>
    import Navbar from '@/components/Navbar'
    export default {
        name: 'LouhuaTest',
        components: {
            Navbar,
        }
    }
</script>