import { mdiBed } from '@mdi/js';
<template>
    <div>公寓详情</div>

    <!-- 走马灯图片 -->
    <Carousel :itemsToShow=carouselItem :wrapAround="true" :transition="500">

        <slide v-for="slide in 1" :key="slide">
            <div class="carousel__item">{{ slide }}</div>
        </slide>

        <template #addons>
            <navigation />
            <Pagination />
        </template>
    </carousel>
    <br>

    <v-row>
        <!-- <v-col cols="1">
        </v-col> -->
        <v-col cols="12">
            <v-card class="mx-auto">

                <v-card-text>
                    <p style="font-size: 30px; font-weight: bold; color: red;">CAD$<span>{{ lpDol }}</span> <span>{{sr}}</span></p>
                    <br>
                    <p style="color: grey; font-size: 18px;"> <span>{{aptNum}}</span> <span>{{ addr }}</span></p>
                    <br>
                    <p style="color: grey; font-size: 18px;"><span>{{ municipality }}</span>, <span>{{ county }}</span> <span>{{ zip }}</span>
                    </p>
                    <br>
                    <p><span>0</span> 在市天数(<span>{{ timestampSql }}</span>)</p>
                    <br>
                    <p> <span style="color: red;"><v-icon icon="mdi-home" /> <span>{{ br }}</span><span>{{ brPlus }}</span> <v-icon icon="mdi-home" />
                            <span>{{ bathTot }}</span><v-icon icon="mdi-home" /> <span>{{ totParkSpcs }}</span> </span> | <span>{{ sqft }}</span>
                        sqft</p>
                </v-card-text>
            </v-card>

            <br>

            <v-card class="mx-auto">

                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">概要</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">ID</span><span
                            style="float:right;">C5858715</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">状态</span><span style="float: right;">Current
                            Listing</span></p>

                    <br>


                    <p><span style="font-size: 15px; font-weight: bold;">产权</span><span style="float: right;">{{ typeOwn1Out  }}</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">类型</span><span style="float: right;">{{ style }}</span></p>

                    <br>
                    <!-- TODO:缺少厨房 -->
                    <p><span style="font-size: 15px; font-weight: bold;">房间</span><span
                            style="float: right;">卧房:{{ br }}{{ brPlus }},厨房:1,浴室:{{ bathTot }}</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">房龄</span><span style="float: right;">{{ assYear }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">管理费（月）</span><span
                            style="float: right;">276.72</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">管理费类型</span><span
                            style="float: right;">保险,取暖,Landscaping,其他,See Remarks,水</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">交接日期</span><span
                            style="float: right;">{{ occ }}</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">挂盘公司</span><span style="float: right;">{{ rltr }}</span></p>

                    <br>
                </v-card-text>

            </v-card>

            <br>

            <v-card class="mx-auto">
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">详细</span></p>

                    <br>
                    <p><span style="font-size: 18px; font-weight: bold;">建筑</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">浴室数量</span><span style="float: right;">{{ bathTot }}</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">卧室数量</span><span style="float: right;">{{ br }}{{ brPlus }}</span>
                    </p>

                    <br>


                    <p><span style="font-size: 15px; font-weight: bold;">空调</span><span
                            style="float: right;">中央空调</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">外墙</span><span
                            style="float: right;">砖，水泥</span></p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">壁炉</span><span style="float: right;">无</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">供暖方式</span><span
                            style="float: right;">天燃气</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">供暖类型</span><span
                            style="float: right;">压力热风</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">使用面积</span><span
                            style="float: right;">N/A</span></p>

                    <br>

                    <p><span style="font-size: 20px; font-weight: bold;">土地</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">面积</span><span
                            style="float: right;">false</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">周边</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">社区特点</span><span style="float: right;">Pets not
                            Allowed</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">设施</span><span
                            style="float: right;">医院,公园,参拜地,公交,周边学校,购物</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">其他</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">特点</span><span style="float: right;">Southern
                            exposure,Corner Site,Park/reserve,阳台</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">地下室</span><span style="float: right;">无</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">设施</span><span
                            style="float: right;">医院,公园,参拜地,公交,周边学校,购物</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">阳台</span><span
                            style="float: right;">Terr</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">储藏室</span><span
                            style="float: right;">None</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">壁炉</span><span style="float: right;">N</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">空调</span><span
                            style="float: right;">{{ac}}</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">供暖</span><span
                            style="float: right;">压力热风</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">电视</span><span style="float: right;">{{ condoExp }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">楼层</span><span style="float: right;">{{ stories }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">房号</span><span style="float: right;">{{aptNum}}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">朝向</span><span style="float: right;">{{ condoExp }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">车位</span><span
                            style="float: right;">{{ totParkSpcs }}</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">COPR #</span><span
                            style="float: right;">FCC86</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">物业管理</span><span
                            style="float: right;">Alwington Communites</span></p>

                    <br>
                </v-card-text>

            </v-card>
            <br>
            <v-card>
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">附注</span></p>

                    <br>
                    <p style="font-size: 16px">{{ adText }}
                    </p>
                    <br>
                    <p style="font-size: 13px">The listing data is provided under copyright by the Toronto Real Estate
                        Board. The listing data is deemed reliable but is not guaranteed accurate by the Toronto Real
                        Estate Board nor RealMaster.</p>
                </v-card-text>
            </v-card>

            <br>
            <v-card>
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold;color: red;">位置</span><span
                            style="float: right;">打开地图<span style="color: blue;"><v-icon icon="mdi-home" /></span>
                        </span></p>

                    <br>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">省：</span></v-col>
                        <v-col cols="6"><span>{{county}}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">城市：</span></v-col>
                        <v-col cols="6"><span>{{municipality}}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">社区：</span></v-col>
                        <v-col cols="6"><span>{{ community }}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">交叉路口：</span></v-col>
                        <v-col cols="6"><span>Princess St And Victoria St</span></v-col>
                    </v-row>


                    <v-row>

                        <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                    <span style="color: blue;">街景<v-icon icon="mdi-home" /></span>
                                </v-card-text></v-card></v-col>
                        <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                    <span style="color: blue;">周边设施<v-icon icon="mdi-home" /></span>
                                </v-card-text></v-card></v-col>
                        <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                    <span style="color: blue;">社区<v-icon icon="mdi-home" /></span>
                                </v-card-text></v-card></v-col>

                    </v-row>

                </v-card-text>
            </v-card>

            <br>




        </v-col>
        <!-- 预约看房 -->
        <!-- <v-col cols="3">
            <v-card class="mx-auto" >
                <template v-slot:title>
                    预约看房
                </template>

                <v-card-text>
                    <v-row>
                        <v-col cols="6"> <v-text-field label="名字">
                            </v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="姓">
                            </v-text-field></v-col>
                    </v-row>

                    <br>
                    <v-text-field label="邮箱">
                    </v-text-field>
                    <br>
                    <v-text-field label="手机">
                    </v-text-field>
                    <br>
                    <v-row>
                        <v-col cols="6"> <v-text-field label="ID">
                            </v-text-field></v-col>
                        <v-col cols="6"> <v-text-field label="微信">
                            </v-text-field></v-col>
                    </v-row>
                    <br>
                    <v-btn class="left" tile color="#A7121D" dark>
                        <div class="white-text">提交</div>
                    </v-btn>
                </v-card-text>

            </v-card>
        </v-col> -->
    </v-row>

    <MoreFilter />
    <br>

</template>

<script>
import MoreFilter from '@/components/MoreFilter'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { locStub } from '@vue/compiler-core'
export default {
    name: 'CondoDetail',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,

    },
    methods: {
        fetchDetailData() {
            fetch('http://8.210.75.35:8080/estate/data/detail', {
                method: 'post',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    // type: type,
                    // id: id,
                    // 测试数据
                    type: 'condo',
                    id: 'C5763339'
                })
            })
                .then(res => res.json())
                .then((response) => {
                    if (response.code === -1) {
                        console.log("错误")
                        return
                    }
                    // 金额加逗号
                    this.lpDol = Number(response.data.lpDol).toLocaleString()
                    // 地址
                    this.addr = response.data.addr
                    // 单元号
                    this.aptNum = "#" + response.data.aptNum
                    // Sale = 出售， Lease = 出租
                    switch(response.data.sr){
                        case 'Sale':
                            this.sr = '出售'
                            break;
                        case 'Lease':
                            this.sr = '出租'
                            break;
                    }
                    // 城市
                    this.municipality = response.data.municipality
                    // 省份
                    this.county = response.data.county
                    // 邮政编码
                    this.zip = response.data.zip
                    // 在市天数取前10位
                    this.timestampSql = (response.data.timestampSql).substring(0,10)
                    // 卧室
                    this.br = response.data.br
                    // Den额外房间 (如果没有不显示)
                    if(response.data.brPlus != '0'){
                        this.brPlus = "+" + response.data.brPlus 
                    }
                    // 卫生间数量
                    this.bathTot = response.data.bathTot
                    // 车位数量(取第一位)
                    this.totParkSpcs = (response.data.totParkSpcs).substring(0,1)
                    // 面积
                    this.sqft = response.data.sqft

                    // ID
                    // 状态

                    // 产权
                    this.typeOwn1Out = response.data.typeOwn1Out

                    // 类型
                    this.style = response.data.style
                    // 房龄
                    if(response.data.assYear != 'null'){
                        this.assYear = response.data.assYear
                    } else {
                        this.assYear = '未知'
                    }
                    // 管理费
                    // 管理费类型
                    // 交接日期
                    if(response.data.occ ='Immediate'){
                        this.occ = '即刻入住'
                    } else {
                        this.occ = response.data.occ
                    }
                    // 挂盘公司
                    this.rltr = response.data.rltr
                    // 空调
                   if(response.data.ac = 'Central Air'){
                        this.ac = '中央空调'
                   }else {
                    this.ac = response.data.ac
                   }
                   // 供暖类型
                   // 使用面积
                   // 面积
                   // 楼层
                   this.stories = response.data.stories
                    // 朝向
                    switch(response.data.condoExp){
                        case 'N':
                            this.condoExp = '北'
                            break;
                        case 'S':
                            this.condoExp = '南'
                            break;
                            case 'E':
                            this.condoExp = '东'
                            break;
                            case 'W':
                            this.condoExp = '西'
                            break;
                    }
                    // 附注
                    this.adText = response.data.adText
                    // 社区
                    this.community = response.data.community
                })
        },
    },
    mounted() {
        // this.fetchDetailData(this.type, this.id)
        this.fetchDetailData()
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            // 屏幕尺寸变化就重新赋值
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    watch: {
        screenWidth: {
            handler: function (val, oldVal) {
                if (val < 800) {
                    this.carouselItem = 1
                    
                } else {
                    this.carouselItem = 3
                }
            },
            immediate: true
        },
    },
    // props: [type, id],
    data() {
        return {
            addr: '',
            ac: '',
            aptNum: '',
            area: '',
            areaCode: '',
            assYear: '',
            br: '',
            brPlus: '',
            community: '',
            communityCode: '',
            ensLndry: '',
            condoExp: '',
            furnished: '',
            idxDt: '',
            rltr: '',
            lpDol: '',
            locker: '',
            mlNum: '',
            municipality: '',
            municipalityCode: '',
            prkgInc: '',
            pets: '',
            pixUpdt: '',
            occ: '',
            zip: '',
            county: '',
            adText: '',
            sr: '',
            status: '',
            stNum: '',
            st: '',
            timestampSql: '',
            totParkSpcs: '',
            unitNum: '',
            tourUrl: '',
            bathTot: '',
            waterInc: '',
            typeOwn1Out: '',
            style: '',
            hydroInc: '',
            allInc: '',
            urlSize: '',
            sqft: '',
            stories: '',
            lng: '',
            lat: '',
            maint:'',
            condoCorp:'',
            corpNum:'',
            constr1Out: '',
            constr2Out: '',
            fuel:'',
            heating:'',
            prop_feat_out:'',
            bsmt1Out:'',
            cable: '',
            crossSt: '',
            imgSrc:[],
            // 屏幕的值
            screenWidth: null,
            carouselItem: 1,

        }
    }
}
</script>
