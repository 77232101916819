<template>
        <v-app-bar 
            color="#ffffff" 
            app
            dark
            class="px-16" 
            >
            <v-btn icon size="large" color="red-darken-2">
                Bread Rent
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="this.$router.push('/')">首页</v-btn>
            <v-btn text @click="scroll('home')">找房</v-btn>
            <v-btn text @click="scroll('sale')">买房</v-btn>
            <v-btn text @click="scroll('lease')">租房</v-btn>
            <v-btn text @click="this.$router.push('/louhua')">楼花</v-btn>
            <v-divider
                class="mx-4 dark-red-text"
                vertical
                />
            <v-btn text @click="showRegisterDialog = true">注册</v-btn>
            <v-btn text >登陆</v-btn>

        </v-app-bar>
        <v-dialog
                v-show="showRegisterDialog"
                persistent
                hide-overlay
                max-width="600px"
                >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">注册</span>
                    </v-card-title>
                </v-card>
            </v-dialog>
        <!-- <RegisterDialog :dialog="showRegisterDialog" /> -->
</template>

<script>

import RegisterDialog from '@/components/RegisterDialog'

export default {

    components: {
        RegisterDialog,
    },
    methods: {
        data() {
            return {
                showRegisterDialog: false,
                showLoginDialog: false,
            }
        },
        scroll(refName)  {
            const element = document.getElementById(refName);
            element.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>

<style>
</style>