import { mdiBed } from '@mdi/js';
<template>

    <!-- 走马灯图片 -->
    <Carousel :itemsToShow=carouselItem :wrapAround="true" :transition="500">

        <slide v-for="index in urlSize" :key="urlSize">
           <img class="carousel__item" :src="imgSrc[index]" style="height:500px">
        </slide>
        <template #addons>
            <navigation />
            <Pagination />
        </template>
    </carousel>

    <br>
    <v-row>
        <v-col cols="12">
            <!-- Condo 第一组 -->
            <v-card class="mx-auto" v-if="condo">

                <v-card-text>
                    <p style="font-size: 30px; font-weight: bold; color: red;">CAD$<span>{{ lpDol }}&nbsp;</span>
                        <span>{{ sr }}</span>
                    </p>
                    <br>
                    <p style="color: grey; font-size: 18px;"> <span>{{ aptNum }}</span> <span>{{ addr }}</span></p>
                    <br>
                    <p style="color: grey; font-size: 18px;"><span>{{ municipality }}</span>, <span>{{ county }},
                        </span>
                        <span>{{ zip }}</span>
                    </p>
                    <br>
                    <p> <span style="color: red;"> <v-icon icon="mdi-bed"></v-icon> <span>{{ br }}</span><span>{{
                        brPlus
                    }}</span> <v-icon icon="mdi-paper-roll" style="margin-left: 5px"></v-icon>
                            <span>{{ bathTot }}<v-icon icon="mdi-car" style="margin-left: 5px"></v-icon> <span>{{
                                totParkSpcs
                            }}</span></span>
                        </span>
                        | <span>{{ sqft }}</span>
                        <span style="font-weight: bold;"> sqft</span>
                    </p>
                </v-card-text>
            </v-card>
            <!-- Res 第一组 -->
            <v-card class="mx-auto" v-if="res">

                <v-card-text>
                    <p style="font-size: 30px; font-weight: bold; color: red;">CAD$<span>{{ lpDol }}&nbsp;</span>
                        <span> {{ sr }}</span>
                    </p>
                    <br>
                    <p style="color: grey; font-size: 18px;"> <span>{{ addr }}</span></p>
                    <br>
                    <p style="color: grey; font-size: 18px;"><span>{{ municipality }}</span>, <span>{{ county }},
                        </span>
                        <span>{{ zip }}</span>
                    </p>
                    <br>
                    <p> <span style="color: red;"> <v-icon icon="mdi-bed"></v-icon><span>{{ br }}</span><span>{{
                        brPlus
                    }}</span> <v-icon icon="mdi-paper-roll" style="margin-left: 5px"></v-icon>
                            <span>{{ bathTot }}</span><v-icon icon="mdi-car" style="margin-left: 5px"></v-icon><span>{{
                                totParkSpcs
                            }}</span>
                        </span>
                        | <span>{{ sqft }}</span>
                        <span style="font-weight: bold;"> sqft</span>
                    </p>



                </v-card-text>
            </v-card>

            <br>
            <!-- Condo 第二组 -->
            <v-card class="mx-auto" v-if="condo">

                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">Summary</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">ID</span><span style="float:right; font-weight: bold;">{{
                        mlNum
                    }}</span></p>

                    <br>



                    <p><span style="font-size: 15px; ">Ownership Type</span><span
                            style="float: right; font-weight: bold;">{{ typeOwn1Out }}</span></p>

                    <br>


                    <p><span style="font-size: 15px; ">Rooms</span><span style="float: right; font-weight: bold;">Bed:{{
                        br
                    }}{{ brPlus }},Kitchen:{{ numKit }},Bath:{{ bathTot }}</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">Constructed Date</span><span
                            style="float: right; font-weight: bold;">{{ assYear }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px;">Prossession</span><span
                            style="float: right; font-weight: bold;">{{ occ }}</span></p>

                    <br>
                    <p><span style="font-size: 15px; ">Brokerage By</span><span
                            style="float: right; font-weight: bold;">{{ rltr }}</span></p>

                    <br>
                </v-card-text>

            </v-card>
            <!-- Res 第二组 -->
            <v-card class="mx-auto" v-if="res">

                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">Summary</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">ID</span><span style="float:right; font-weight: bold;">{{
                        mlNum
                    }}</span></p>

                    <br>



                    <p><span style="font-size: 15px;">Ownership Type</span><span
                            style="float: right; font-weight: bold;">{{ typeOwn1Out }}</span></p>

                    <br>


                    <p><span style="font-size: 15px;">Rooms</span><span style="float: right; font-weight: bold;">Bed:{{
                        br
                    }}{{ brPlus }},Kitchen:{{ numKit }},Bath:{{ bathTot }}</span></p>

                    <br>

                    <p><span style="font-size: 15px;">Constructed Date</span><span
                            style="float: right; font-weight: bold;">{{ assYear }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px;">Prossession</span><span
                            style="float: right; font-weight: bold;">{{ occ }}</span></p>

                    <br>
                    <p><span style="font-size: 15px;">Brokerage By</span><span
                            style="float: right; font-weight: bold;">{{ rltr }}</span></p>

                    <br>
                </v-card-text>

            </v-card>
            <br>
            <!-- Condo 第三组 -->
            <v-card class="mx-auto" v-if="condo">
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">Detail</span></p>

                    <br>
                    <p><span style="font-size: 18px; font-weight: bold;">Building</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">Bathroom Total</span><span
                            style="float: right; font-weight: bold;">{{ bathTot }}</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; font-weight: bold;">Bedroom Total</span><span
                            style="float: right; font-weight: bold;">{{ br }}{{ brPlus }}</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; ">Exterior Finish</span><span
                            style="float: right; font-weight: bold;">{{ constr1Out }} {{ constr2Out }}</span></p>

                    <br>
                    <!-- 
                    <p><span style="font-size: 15px; font-weight: bold;">Fireplace Present</span><span style="float: right;">无</span>
                    </p>

                    <br> -->

                    <p><span style="font-size: 15px; ">Heating Fuel</span><span
                            style="float: right; font-weight: bold;">{{ fuel }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; ">Heating Type</span><span
                            style="float: right; font-weight: bold;">{{ heating }}</span></p>

                    <br>
                    <!-- <p><span style="font-size: 15px; font-weight: bold;">Size Interior</span><span
                            style="float: right;">N/A</span></p>

                    <br> -->

                    <!-- <p><span style="font-size: 20px; font-weight: bold;">Land</span></p>

                    <br>
                    <p><span style="font-size: 15px; font-weight: bold;">Acreage</span><span
                            style="float: right;">false</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">Surrounding</span></p>

                    <br> -->
                    <!-- <p><span style="font-size: 15px; font-weight: bold;">Community Features</span><span style="float: right;">Pets not
                            Allowed</span></p>

                    <br> -->
                    <p><span style="font-size: 15px; ">Ammenities Near By</span><span
                            style="float: right; font-weight: bold;">{{ prop_feat_out }}</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">Other</span></p>

                    <br>
                    <!-- <p><span style="font-size: 15px; font-weight: bold;">Features</span><span style="float: right;">Southern
                            exposure,Corner Site,Park/reserve,阳台</span></p>

                    <br> -->
                    <p><span style="font-size: 15px; ">Basement</span><span style="float: right; font-weight: bold;">{{
                        bsmt1Out
                    }}</span>
                    </p>


                    <br>
                    <p><span style="font-size: 15px; ">Locker</span><span style="float: right; font-weight: bold;">{{
                        locker
                    }}</span></p>

                    <!-- <br>
                    <p><span style="font-size: 15px; font-weight: bold;">Fireplace</span><span style="float: right;">N</span>
                    </p> -->

                    <br>
                    <p><span style="font-size: 15px;">A/C</span><span style="float: right; font-weight: bold;">{{
                        ac
                    }}</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">TV</span><span style="float: right; font-weight: bold;">{{
                        cable
                    }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; ">Level</span><span style="float: right; font-weight: bold;">{{
                        stories
                    }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; ">Unit No.</span><span style="float: right; font-weight: bold;">{{
                        aptNum
                    }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; ">Exposure</span><span style="float: right; font-weight: bold;">{{
                        condoExp
                    }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; ">Parking</span><span style="float: right; font-weight: bold;">{{
                        totParkSpcs
                    }}</span></p>

                    <br>
                    <p><span style="font-size: 15px; ">{{ condoCorp }}</span><span
                            style="float: right; font-weight: bold;">{{ corpNum }}</span></p>

                    <br>
                    <!-- <p><span style="font-size: 15px; font-weight: bold;">物业管理</span><span
                            style="float: right;">Alwington Communites</span></p>

                    <br> -->
                </v-card-text>

            </v-card>
            <!-- Res 第三组 -->
            <v-card class="mx-auto" v-if="res">
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">Detail</span></p>

                    <br>
                    <p><span style="font-size: 18px; font-weight: bold;">Building</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">Bathroom Total</span><span
                            style="float: right; font-weight: bold;">{{ bathTot }}</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; ">Bedroom Total</span><span
                            style="float: right; font-weight: bold;">{{ br }}{{ brPlus }}</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; ">Heating Fuel</span><span
                            style="float: right; font-weight: bold;">{{ fuel }}</span>
                    </p>

                    <br>
                    <p><span style="font-size: 15px; ">Heating Type</span><span
                            style="float: right; font-weight: bold;">{{ heating }}</span></p>

                    <br>

                    <p><span style="font-size: 15px; ">Ammenities Near By</span><span
                            style="float: right; font-weight: bold;">{{ prop_feat_out }}</span></p>

                    <br>
                    <p><span style="font-size: 20px; font-weight: bold;">Other</span></p>

                    <br>
                    <!-- <p><span style="font-size: 15px; font-weight: bold;">Features</span><span style="float: right;">Southern
                            exposure,Corner Site,Park/reserve,阳台</span></p>

                    <br> -->
                    <p><span style="font-size: 15px; ">Basement</span><span style="float: right; font-weight: bold;">{{
                        bsmt1Out
                    }}</span>
                    </p>

                    <br>

                    <p><span style="font-size: 15px; ">TV</span><span style="float: right; font-weight: bold;">{{
                        cable
                    }}</span>
                    </p>


                    <br>
                    <p><span style="font-size: 15px; ">Parking</span><span style="float: right; font-weight: bold;">{{
                        totParkSpcs
                    }}</span></p>

                    <br>
                    <p><span style="font-size: 15px; ">Tax</span><span
                            style="float: right; font-weight: bold;">{{ tax }}</span></p>

                    <br>
                </v-card-text>

            </v-card>
            <br>
            <v-card>
                <v-card-text>
                    <p><span style="font-size: 20px; font-weight: bold; color: red;">Remarks</span></p>

                    <br>
                    <p style="font-size: 16px">{{ adText }}
                    </p>
                    <br>
                    <p style="font-size: 13px">The listing data is provided under copyright by the Toronto Real Estate
                        Board. The listing data is deemed reliable but is not guaranteed accurate by the Toronto Real
                        Estate Board nor BreadRent.</p>
                </v-card-text>
            </v-card>

            <br>




        </v-col>
        <!-- 预约看房 -->
        <!-- <v-col cols="3">
            <v-card class="mx-auto" >
                <template v-slot:title>
                    预约看房
                </template>

                <v-card-text>
                    <v-row>
                        <v-col cols="6"> <v-text-field label="名字">
                            </v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="姓">
                            </v-text-field></v-col>
                    </v-row>

                    <br>
                    <v-text-field label="邮箱">
                    </v-text-field>
                    <br>
                    <v-text-field label="手机">
                    </v-text-field>
                    <br>
                    <v-row>
                        <v-col cols="6"> <v-text-field label="ID">
                            </v-text-field></v-col>
                        <v-col cols="6"> <v-text-field label="微信">
                            </v-text-field></v-col>
                    </v-row>
                    <br>
                    <v-btn class="left" tile color="#A7121D" dark>
                        <div class="white-text">提交</div>
                    </v-btn>
                </v-card-text>

            </v-card>
        </v-col> -->
    </v-row>

    <MoreFilter />
    <br>

</template>

<script>
import MoreFilter from '@/components/MoreFilter'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiBedOutline, mdiShower, mdiCarOutline } from '@mdi/js';
export default {
    name: 'CondoDetail',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        SvgIcon,

    },
    methods: {
        fetchDetailData() {
            // N5648004 2780000 RES
            // fetch('http://118.195.179.89:8100/api/bread/landing/map/detail?mlNum=N5648004', {
                fetch('http://118.195.179.89:8100/api/bread/landing/map/detail?mlNum=C5654088', {
                method: 'get',
                headers: { 'Content-type': 'application/json' },
            })
                .then(res => res.json())
                .then((response) => {
                    if (response.code === -1) {
                        console.log("错误")
                        return
                    }
                    if (response.data.type === 'condo') {
                        // 公寓的逻辑
                        this.condo = true
                        // 图片数量
                        this.urlSize = response.data.condo.urlSize
                        // 金额加逗号
                        this.lpDol = Number(response.data.condo.lpDol).toLocaleString()
                        // 租/售
                        this.sr = response.data.condo.s_r
                        // 地址
                        this.addr = response.data.condo.addr
                        // 单元号
                        this.aptNum = "#" + response.data.condo.aptNum
                        // 城市
                        this.municipality = response.data.condo.municipality
                        // 省份
                        this.county = response.data.condo.county
                        // 邮政编码
                        this.zip = response.data.condo.zip
                        // 在市天数取前10位
                        this.timestampSql = (response.data.condo.timestampSql).substring(0, 10)
                        // 卧室
                        this.br = response.data.condo.br
                        // Den额外房间 (如果没有不显示)
                        if (response.data.condo.brPlus != '0') {
                            this.brPlus = "+" + response.data.condo.brPlus
                        }
                        // 厨房
                        this.numKit = response.data.condo.numKit
                        // 卫生间数量
                        this.bathTot = response.data.condo.bathTot
                        // 车位数量(取第一位)
                        this.totParkSpcs = (response.data.condo.totParkSpcs).substring(0, 1)
                        // 面积
                        this.sqft = response.data.condo.sqft
                        // ID
                        this.mlNum = response.data.condo.mlNum
                        // 状态
                        // 产权
                        this.typeOwn1Out = response.data.condo.typeOwn1Out
                        // 类型
                        this.style = response.data.condo.style
                        // 房龄
                        if (response.data.condo.assYear != 'null') {
                            this.assYear = response.data.condo.assYear
                        } else {
                            this.assYear = 'Unknown'
                        }
                        // 管理费
                        this.maint = response.data.condo.maint
                        // 管理费类型
                        // 交接日期
                        this.occ = response.data.condo.occ
                        // 挂盘公司
                        this.rltr = response.data.condo.rltr
                        // 外墙
                        this.constr1Out = response.data.condoDetail.constr1Out
                        if (response.data.condoDetail.constr2Out != 'null') {
                            this.constr2Out = ", " + response.data.condoDetail.constr2Out
                        } else {
                            this.constr2Out = ""
                        }

                        // 楼层
                        this.stories = response.data.condo.stories
                        // 朝向
                        switch (response.data.condo.condoExp) {
                            case 'N':
                                this.condoExp = '北'
                                break;
                            case 'S':
                                this.condoExp = '南'
                                break;
                            case 'E':
                                this.condoExp = '东'
                                break;
                            case 'W':
                                this.condoExp = '西'
                                break;
                            default:
                                this.condoExp = (response.data.condo.condoExp).toUpperCase()
                        }
                        // 附注
                        this.adText = response.data.condo.adText
                        // 社区
                        this.community = response.data.condo.community

                        // 物业前缀
                        this.condoCorp = response.data.condo.condoCorp + " #"
                        // 物业Num
                        this.corpNum = response.data.condo.corpNum
                        // 供暖方式
                        this.fuel = response.data.condoDetail.fuel
                        // 供暖类型
                        this.heating = response.data.condoDetail.heating
                        // 使用面积
                        // 面积

                        // 设施
                        this.prop_feat_out = response.data.condoDetail.propFeat1Out + " " + response.data.condoDetail.propFeat2Out +
                            " " + response.data.condoDetail.propFeat3Out + " " + response.data.condoDetail.propFeat4Out +
                            " " + response.data.condoDetail.propFeat5Out + " " + response.data.condoDetail.propFeat6Out

                        // 地下室
                        this.bsmt1Out = response.data.condoDetail.bsmt1Out

                        // 储藏室
                        this.locker = response.data.condo.locker

                        // 电视
                        this.cable = response.data.condo.cable

                        // 交叉路口
                        this.crossSt = (response.data.condo.crossSt).replace('/', ' And ')

                        for (var i = 0; i <= parseInt(this.urlSize); i++) {
                            this.imgSrc[i] = "http://www.breadrent.com/CondoMedia/" + this.mlNum + "/" + (i) + ".jpeg"
                        }

                    } else if (response.data.type === 'res') {
                        // 独立屋的逻辑
                        this.res = true
                        // 图片数量
                        this.urlSize = response.data.res.urlSize
                        // 金额加逗号
                        this.lpDol = Number(response.data.res.lpDol).toLocaleString()
                        // 租/售
                        this.sr = response.data.res.s_r
                        // 地址
                        this.addr = response.data.res.addr
                        // 单元号
                        this.aptNum = "#" + response.data.res.aptNum
                        // 城市
                        this.municipality = response.data.res.municipality
                        // 省份
                        this.county = response.data.res.county
                        // 邮政编码
                        this.zip = response.data.res.zip
                        // 在市天数取前10位
                        this.timestampSql = (response.data.res.timestampSql).substring(0, 10)
                        // 卧室
                        this.br = response.data.res.br
                        // Den额外房间 (如果没有不显示)
                        if (response.data.res.brPlus != '0') {
                            this.brPlus = "+" + response.data.res.brPlus
                        }
                        // 厨房
                        this.numKit = response.data.res.numKit
                        // 卫生间数量
                        this.bathTot = response.data.res.bathTot
                        // 车位数量(取第一位)
                        this.totParkSpcs = (response.data.res.totParkSpcs).substring(0, 1)
                        // 面积
                        if (response.data.res.sqft != 'null') {
                            this.sqft = response.data.res.sqft
                        } else {
                            this.sqft = 'Unknown'
                        }

                        // ID
                        this.mlNum = response.data.res.mlNum
                        // 状态
                        // 产权
                        this.typeOwn1Out = response.data.res.typeOwn1Out
                        // 类型
                        this.style = response.data.res.style
                        // 房龄
                        if (response.data.res.yrBuilt != 'null') {
                            this.assYear = response.data.res.yrBuilt
                        } else {
                            this.assYear = 'Unknown'
                        }
                        // 交接日期
                        this.occ = response.data.res.occ
                        // 挂盘公司
                        this.rltr = response.data.res.rltr

                        // 供暖方式
                        this.fuel = response.data.resDetail.fuel
                        // 供暖类型
                        this.heating = response.data.resDetail.heating
                        // 设施
                        if (response.data.resDetail.propFeat1Out == 'null') {
                            this.prop_feat_out = 'Unknown'
                        } else {
                            this.prop_feat_out = response.data.resDetail.propFeat1Out + " " + response.data.resDetail.propFeat2Out +
                                " " + response.data.resDetail.propFeat3Out + " " + response.data.resDetail.propFeat4Out +
                                " " + response.data.resDetail.propFeat5Out + " " + response.data.resDetail.propFeat6Out
                        }


                        // 电视
                        this.cable = response.data.res.cable
                        // 附注
                        this.adText = response.data.res.adText
                        this.bsmt1Out = response.data.resDetail.bsmt1Out
                        // 税
                        this.tax = "$ " + Number(response.data.res.taxes).toLocaleString()

                        for (var i = 0; i <= parseInt(this.urlSize); i++) {
                            this.imgSrc[i] = "http://www.breadrent.com/ResMedia/" + this.mlNum + "/" + (i) + ".jpeg"
                        }


                    } else {
                        // Error handle
                        this.lpDol = '数据获取错误'
                    }


                })
        },
    },
    mounted() {
        // this.fetchDetailData(this.type, this.id)
        this.fetchDetailData()
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            // 屏幕尺寸变化就重新赋值
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    watch: {
        screenWidth: {
            handler: function (val, oldVal) {
                if (val < 800) {
                    this.carouselItem = 1

                } else {
                    this.carouselItem = 3
                }
            },
            immediate: true
        },
    },
    // props: [type, id],
    data() {
        return {
            bedPath: mdiBedOutline,
            bathPath: mdiShower,
            parkingPath: mdiCarOutline,
            condo: false,
            res: false,
            addr: '',
            ac: '',
            aptNum: '',
            area: '',
            areaCode: '',
            assYear: '',
            br: '',
            brPlus: '',
            community: '',
            communityCode: '',
            ensLndry: '',
            condoExp: '',
            furnished: '',
            idxDt: '',
            rltr: '',
            lpDol: '',
            locker: '',
            mlNum: '',
            municipality: '',
            municipalityCode: '',
            prkgInc: '',
            pets: '',
            pixUpdt: '',
            occ: '',
            zip: '',
            county: '',
            adText: '',
            sr: '',
            status: '',
            stNum: '',
            st: '',
            timestampSql: '',
            totParkSpcs: '',
            unitNum: '',
            tourUrl: '',
            bathTot: '',
            waterInc: '',
            typeOwn1Out: '',
            style: '',
            hydroInc: '',
            allInc: '',
            urlSize: '',
            sqft: '',
            stories: '',
            lng: '',
            lat: '',
            numKit: '',
            tax: '',

            imgSrc: [],
            // 屏幕的值
            screenWidth: null,
            carouselItem: 1,

        }
    }
}
</script>
