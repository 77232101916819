import { mdiBed } from '@mdi/js';

<template>

    <!-- PDF打印页面 -->
    <!-- <div id="PdfPage1">
        <button type="button" class="btn btn-primary" @click="pdfBtn">导出PDF</button>
        <div id="pdfDom" style="padding:10px;background-color:#fff;">
            <img alt="Vue logo" src="@/assets/logo.png">
            <h1>Welcome to Your Vue.js App</h1>
            <p v-for="(item,index) in 5" :key="index">{{item}}Welcome to Your Vue.js App</p>
        </div>
    </div> -->
    <div style="background-color: #ebebeb;">
        <div style="position: relative; height:320px ;">
            <v-row>
                <v-col cols="12">
                    <v-carousel height="320" cycle="true" hide-delimiter-background="true" hide-delimiters="true"
                        color="white" style="min-width: 353px; display: block; margin-left: auto; margin-right: auto;">
                        <v-carousel-item v-for="index in urlSize" :key="urlSize" :src="imgSrc[index]">
                        </v-carousel-item>

                    </v-carousel>
                </v-col>
            </v-row>


            <v-row style="z-index: 10; position: absolute; bottom: 10px; right: 0px;" justify="space-around">
                <v-col>

                    <v-chip size="small" color="error" variant="elevated" style="opacity: 0.8;">
                        <span style="color: white; z-index: 12;"><v-icon icon="mdi-home" />VT</span>
                    </v-chip>
                </v-col>
                <v-col>

                    <v-chip size="small" color="error" variant="elevated" style="opacity: 0.8;">
                        <span style="color: white; z-index: 2;"><span style="color: red;">1</span>/{{ urlSize }}</span>
                    </v-chip>
                </v-col>
                <v-col>

                    <v-chip size="small" color="error" variant="elevated" style="opacity: 0.8;">
                        <v-icon icon="mdi-home" style="color: white; z-index: 2;" />
                    </v-chip>
                </v-col>
            </v-row>
        </div>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <!-- Condo 第一组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px"  v-if="condo">

                    <v-card-text>
                        <p
                            style="font-size: 30px; font-weight: bold; color: red;font-family: Arial, Helvetica, sans-serif;">
                            CAD$<span>{{ lpDol }} {{ sr }}</span>
                        </p>
                        <br>
                        <p style="color: grey; font-size: 18px; font-weight: bold;"> <span>{{ aptNum }}</span> <span>{{
                            addr
                        }}</span></p>
                        <br>
                        <p style="color: grey; font-size: 18px;"><span>{{ municipality }}</span>, <span>{{
                            county
                        }}, </span>
                            <span>{{ zip }}</span>
                        </p>
                        <br>

                        <br>
                        <p> <span style="color: red;"><v-icon icon="mdi-bed"></v-icon> <span>{{ br }}</span><span>{{
                            brPlus
                        }}</span> <v-icon icon="mdi-paper-roll" style="margin-left: 5px"></v-icon>
                                <span>{{ bathTot }}</span> <v-icon icon="mdi-car"  style="margin-left: 5px"></v-icon><span>{{ totParkSpcs }}</span>
                            </span>
                            | <span>{{ sqft }}</span>
                            <span style="font-weight: bold;"> sqft</span>
                        </p>
                    </v-card-text>
                </v-card>

                <!-- Res 第一组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px" v-if="res">

                    <v-card-text>
                        <p
                            style="font-size: 30px; font-weight: bold; color: red;font-family: Arial, Helvetica, sans-serif;">
                            CAD$<span>{{ lpDol }} {{ sr }}</span>
                        </p>
                        <br>
                        <p style="color: grey; font-size: 18px; font-weight: bold;"><span>{{
                            addr
                        }}</span></p>
                        <br>
                        <p style="color: grey; font-size: 18px;"><span>{{ municipality }}</span>, <span>{{
                            county
                        }}，</span>
                            <span>{{ zip }}</span>
                        </p>
                        <br>

                        <br>
                        <p> <span style="color: red;"><v-icon icon="mdi-bed"></v-icon><span>{{ br }}</span><span>{{
                            brPlus
                        }}</span> <v-icon icon="mdi-paper-roll" style="margin-left: 5px"></v-icon>
                                <span>{{ bathTot }}</span><v-icon icon="mdi-car"  style="margin-left: 5px"></v-icon> <span>{{ totParkSpcs }}</span>
                            </span>
                            | <span>{{ sqft }}</span>
                            <span style="font-weight: bold;"> sqft</span>
                        </p>
                    </v-card-text>
                </v-card>

                <br>
                <!-- Condo 第二组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px"  v-if="condo">


                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold; color: red;">概要</span></p>

                        <br>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">ID</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ mlNum }}</span>
                            </v-col>
                        </v-row>

                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">状态</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">H Current
                                    Listing</span>
                            </v-col>
                        </v-row> -->



                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">产权</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ typeOwn1Out }}</span>
                            </v-col>
                        </v-row>

                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">类型</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">H 民宅
                                   </span>
                            </v-col>
                        </v-row> -->

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">房间</span>
                            </v-col>
                            <v-col cols="6">
                                <span class="text-wrapper" style="font-size: 15px; font-weight: bold;">卧房:{{ br }}{{
                                    brPlus
                                }},厨房:1,浴室:{{
    bathTot
}}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">房龄</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ assYear }}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">管理费（月）</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ maint }}</span>
                            </v-col>
                        </v-row>

                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">管理费类型</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">H 保险,取暖,Landscaping</span>
                            </v-col>
                        </v-row> -->

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">交接日期</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ occ }}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">挂盘公司</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ rltr }}</span>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>

                <!-- Res 第二组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px" v-if="res">


                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold; color: red;">概要</span></p>

                        <br>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">ID</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ mlNum }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">产权</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ typeOwn1Out }}</span>
                            </v-col>
                        </v-row>


                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">房间</span>
                            </v-col>
                            <v-col cols="6">
                                <span class="text-wrapper" style="font-size: 15px; font-weight: bold;">卧房:{{ br }}{{
                                    brPlus
                                }},浴室:{{
    bathTot
}}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">房龄</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ assYear }}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">交接日期</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ occ }}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">挂盘公司</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-size: 15px; font-weight: bold;">{{ rltr }}</span>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>

                <br>
                <!-- Condo 第三组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px"  v-if="condo">

                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold; color: red;">详细</span></p>

                        <br>

                        <p><span style="font-size: 18px; font-weight: bold;">建筑</span></p>

                        <br>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">浴室数量</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ bathTot }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">卧室数量</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ br }}{{ brPlus }}</span>
                            </v-col>
                        </v-row>

                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">空调</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ ac }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">外墙</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ constr1Out }} {{ constr2Out }}</span>
                            </v-col>
                        </v-row>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">壁炉</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H 无</span>
                            </v-col>
                        </v-row> -->
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">供暖方式</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ fuel }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">供暖类型</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ heating }}</span>
                            </v-col>
                        </v-row>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">使用面积</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H N/A</span>
                            </v-col>
                        </v-row> -->
                        <!-- 缺少 -->
                        <!-- <br>
                        <p><span style="font-size: 20px; font-weight: bold;">土地</span></p>

                        <br>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">面积</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H false</span>
                            </v-col>
                        </v-row> -->
                        <br>
                        <p><span style="font-size: 20px; font-weight: bold;">周边</span></p>

                        <br>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">社区特点</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H Pets not
                                    Allowed</span>
                            </v-col>
                        </v-row> -->
                        <!-- <br> -->
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">设施</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ prop_feat_out }}</span>
                            </v-col>
                        </v-row>
                        <br>
                        <p><span style="font-size: 20px; font-weight: bold;">其他</span></p>

                        <br>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">特点</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H Southern
                                    exposure,Corner Site,Park/reserve,阳台</span>
                            </v-col>
                        </v-row> -->
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">地下室</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;"> {{ bsmt1Out }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">设施</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ prop_feat_out }}</span>
                            </v-col>
                        </v-row>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">阳台</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H Terr</span>
                            </v-col>
                        </v-row> -->
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">储藏室</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ locker }}</span>
                            </v-col>
                        </v-row>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">壁炉</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H N</span>
                            </v-col>
                        </v-row> -->
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">空调</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ ac }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">电视</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ cable }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">楼层</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ stories }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">房号</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ aptNum }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">朝向</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ condoExp }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">车位</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ totParkSpcs }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">{{ condoCorp }}</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ corpNum }}</span>
                            </v-col>
                        </v-row>
                        <!-- 缺少 -->
                        <!-- <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">物业管理</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">H Alwington Communites</span>
                            </v-col>
                        </v-row> -->
                    </v-card-text>

                </v-card>

                <!-- Res 第三组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px" v-if="res">

                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold; color: red;">详细</span></p>

                        <br>

                        <p><span style="font-size: 18px; font-weight: bold;">建筑</span></p>

                        <br>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">浴室数量</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ bathTot }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">卧室数量</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ br }}{{ brPlus }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">供暖方式</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ fuel }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">供暖类型</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ heating }}</span>
                            </v-col>
                        </v-row>
                        <br>
                        <p><span style="font-size: 20px; font-weight: bold;">周边</span></p>

                        <br>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">设施</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ prop_feat_out }}</span>
                            </v-col>
                        </v-row>
                        <br>
                        <p><span style="font-size: 20px; font-weight: bold;">其他</span></p>

                        <br>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">地下室</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;"> {{ bsmt1Out }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">设施</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ prop_feat_out }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px; ">电视</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ cable }}</span>
                            </v-col>
                        </v-row>
                        <v-row dense="0">
                            <v-col cols="6">
                                <span style="font-size: 15px;">车位</span>
                            </v-col>
                            <v-col cols="6">
                                <span style="font-weight: bold;">{{ totParkSpcs }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-card>

                <br>


                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px">
                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold; color: red;">附注</span></p>

                        <br>
                        <p style="font-size: 16px">{{ adText }}
                        </p>
                        <br>
                        <p style="font-size: 13px">H The listing data is provided under copyright by the Toronto Real
                            Estate
                            Board. The listing data is deemed reliable but is not guaranteed accurate by the Toronto
                            Real
                            Estate Board nor BreadRent.</p>
                    </v-card-text>
                </v-card>

                <br>
                <!-- Condo 第四组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px" v-if="condo">
                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold;color: red;">位置</span><span
                                style="float: right;">打开地图<span style="color: blue;"><v-icon icon="mdi-home" /></span>
                            </span></p>

                        <br>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">省：</span></v-col>
                            <v-col cols="6"><span>{{ county }}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">城市：</span></v-col>
                            <v-col cols="6"><span>{{ municipality }}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">社区：</span></v-col>
                            <v-col cols="6"><span>{{ community }}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">交叉路口：</span></v-col>
                            <v-col cols="6"><span>{{ crossSt }}</span></v-col>
                        </v-row>


                        <v-row>

                            <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                        <span style="color: blue;">街景<v-icon icon="mdi-home" /></span>
                                    </v-card-text></v-card></v-col>
                            <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                        <span style="color: blue;">周边设施<v-icon icon="mdi-home" /></span>
                                    </v-card-text></v-card></v-col>
                            <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                        <span style="color: blue;">社区<v-icon icon="mdi-home" /></span>
                                    </v-card-text></v-card></v-col>

                        </v-row>

                    </v-card-text>
                </v-card>
                <!-- Condo 第四组 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px" v-if="res">
                    <v-card-text>
                        <p><span style="font-size: 20px; font-weight: bold;color: red;">位置</span><span
                                style="float: right;">打开地图<span style="color: blue;"><v-icon icon="mdi-home" /></span>
                            </span></p>

                        <br>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">省：</span></v-col>
                            <v-col cols="6"><span>{{ county }}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">城市：</span></v-col>
                            <v-col cols="6"><span>{{ municipality }}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6"><span style="font-size: 15px; font-weight: bold;">社区：</span></v-col>
                            <v-col cols="6"><span>{{ community }}</span></v-col>
                        </v-row>


                        <v-row>

                            <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                        <span style="color: blue;">街景<v-icon icon="mdi-home" /></span>
                                    </v-card-text></v-card></v-col>
                            <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                        <span style="color: blue;">周边设施<v-icon icon="mdi-home" /></span>
                                    </v-card-text></v-card></v-col>
                            <v-col cols="4"><v-card variant="outlined" style="text-align:center"> <v-card-text>
                                        <span style="color: blue;">社区<v-icon icon="mdi-home" /></span>
                                    </v-card-text></v-card></v-col>

                        </v-row>

                    </v-card-text>
                </v-card>
                <br>
                <!-- 预约看房 -->
                <v-card class="mx-auto" flat="true" max-width="576" style="min-width: 353px">
                    <template v-slot:title>
                        预约看房
                    </template>

                    <v-card-text>
                        <v-row>
                            <v-col cols="6"> <v-text-field label="名字">
                                </v-text-field></v-col>
                            <v-col cols="6"><v-text-field label="姓">
                                </v-text-field></v-col>
                        </v-row>

                        <br>
                        <v-text-field label="邮箱">
                        </v-text-field>
                        <br>
                        <v-text-field label="手机">
                        </v-text-field>
                        <br>
                        <v-row>
                            <v-col cols="6"> <v-text-field label="ID">
                                </v-text-field></v-col>
                            <v-col cols="6"> <v-text-field label="微信">
                                </v-text-field></v-col>
                        </v-row>
                        <br>
                        <v-row>
                            <v-col cols="5">
                            </v-col>
                            <v-col cols="2"> <v-btn class="center" tile color="#A7121D" dark>
                                    <div class="white-text">提交</div>
                                </v-btn>
                            </v-col>
                            <v-col cols="5">
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>



            </v-col>
        </v-row>

        <MoreFilter />
        <br>
    </div>
</template>

<script>
import MoreFilter from '@/components/MoreFilter'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import htmlToPdf from '@/plugins/htmlToPdf'
import { addMapping } from '@jridgewell/gen-mapping'
export default {

    name: 'ShareCondoDetail',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,

    },
    methods: {
        fetchDetailData() {
            // N5648004 2780000 RES
            // W5568364
            // fetch('http://118.195.179.89:8100/api/bread/landing/map/detail?mlNum=N5648004', {
            fetch('http://118.195.179.89:8100/api/bread/landing/map/detail?mlNum=C5654088', {
                method: 'get',
                headers: { 'Content-type': 'application/json' },
            })
                .then(res => res.json())
                .then((response) => {
                    if (response.code === -1) {
                        console.log("错误")
                        return
                    }
                    if (response.data.type === 'condo') {
                        // 公寓的逻辑
                        this.condo = true
                        // 图片数量
                        this.urlSize = response.data.condo.urlSize
                        // 金额加逗号
                        this.lpDol = Number(response.data.condo.lpDol).toLocaleString()
                        // 租/售
                        if (response.data.condo.s_r = 'Lease') {
                            this.sr = '出租'
                        } else if (response.data.condo.s_r = 'Sale') {
                            this.sr = '出售'
                        }
                        // 地址
                        this.addr = response.data.condo.addr
                        // 单元号
                        this.aptNum = "#" + response.data.condo.aptNum
                        // 城市
                        this.municipality = response.data.condo.municipality
                        // 省份
                        this.county = response.data.condo.county
                        // 邮政编码
                        this.zip = response.data.condo.zip
                        // 在市天数取前10位
                        this.timestampSql = (response.data.condo.timestampSql).substring(0, 10)
                        // 卧室
                        this.br = response.data.condo.br
                        // Den额外房间 (如果没有不显示)
                        if (response.data.condo.brPlus != '0') {
                            this.brPlus = "+" + response.data.condo.brPlus
                        }
                        // 卫生间数量
                        this.bathTot = response.data.condo.bathTot
                        // 车位数量(取第一位)
                        this.totParkSpcs = (response.data.condo.totParkSpcs).substring(0, 1)
                        // 面积
                        this.sqft = response.data.condo.sqft
                        // ID
                        this.mlNum = response.data.condo.mlNum
                        // 状态
                        // 产权
                        this.typeOwn1Out = response.data.condo.typeOwn1Out
                        // 类型
                        this.style = response.data.condo.style
                        // 房龄
                        if (response.data.condo.assYear != 'null') {
                            this.assYear = response.data.condo.assYear
                        } else {
                            this.assYear = '未知'
                        }
                        // 管理费
                        this.maint = response.data.condo.maint
                        // 管理费类型
                        // 交接日期
                        if (response.data.condo.occ = 'Immediate') {
                            this.occ = '即刻入住'
                        } else {
                            this.occ = response.data.condo.occ
                        }
                        // 挂盘公司
                        this.rltr = response.data.condo.rltr

                        // 空调
                        if (response.data.condo.ac = 'Central Air') {
                            this.ac = '中央空调'
                        } else {
                            this.ac = "$ " + response.data.condo.ac
                        }
                        // 外墙
                        this.constr1Out = response.data.condoDetail.constr1Out
                        if (response.data.condoDetail.constr2Out != 'null') {
                            this.constr2Out = ", " + response.data.condoDetail.constr2Out
                        } else {
                            this.constr2Out = ""
                        }

                        // 楼层
                        this.stories = response.data.condo.stories
                        // 朝向
                        switch (response.data.condo.condoExp) {
                            case 'N':
                                this.condoExp = '北'
                                break;
                            case 'S':
                                this.condoExp = '南'
                                break;
                            case 'E':
                                this.condoExp = '东'
                                break;
                            case 'W':
                                this.condoExp = '西'
                                break;
                            default:
                                this.condoExp = (response.data.condo.condoExp).toUpperCase()
                        }
                        // 附注
                        this.adText = response.data.condo.adText
                        // 社区
                        this.community = response.data.condo.community

                        // 物业前缀
                        this.condoCorp = response.data.condo.condoCorp + " #"
                        // 物业Num
                        this.corpNum = response.data.condo.corpNum
                        // 供暖方式
                        this.fuel = response.data.condoDetail.fuel
                        // 供暖类型
                        this.heating = response.data.condoDetail.heating
                        // 使用面积
                        // 面积

                        // 设施
                        if (response.data.condoDetail.propFeat1Out == 'null') {
                            this.prop_feat_out = '未知'
                        } else {
                            this.prop_feat_out = response.data.condoDetail.propFeat1Out + " " + response.data.condoDetail.propFeat2Out +
                                " " + response.data.condoDetail.propFeat3Out + " " + response.data.condoDetail.propFeat4Out +
                                " " + response.data.condoDetail.propFeat5Out + " " + response.data.condoDetail.propFeat6Out
                        }

                        // 地下室
                        this.bsmt1Out = response.data.condoDetail.bsmt1Out

                        // 储藏室
                        this.locker = response.data.condo.locker

                        // 电视
                        this.cable = response.data.condo.cable

                        // 交叉路口
                        this.crossSt = (response.data.condo.crossSt).replace('/', ' And ')

                        for (var i = 0; i <= parseInt(this.urlSize); i++) {
                            this.imgSrc[i] = "http://www.breadrent.com/CondoMedia/" + this.mlNum + "/" + (i) + ".jpeg"
                        }

                    } else if (response.data.type === 'res') {
                        // 独立屋的逻辑
                        this.res = true
                        // 图片数量
                        this.urlSize = response.data.res.urlSize
                        // 金额加逗号
                        this.lpDol = Number(response.data.res.lpDol).toLocaleString()
                        // 租/售
                        if (response.data.res.s_r == 'Sale') {
                            this.sr = '出售'
                        } else if (response.data.res.s_r == 'Lease') {
                            this.sr = '出租'
                        }

                        // 地址
                        this.addr = response.data.res.addr
                        // 单元号
                        this.aptNum = "#" + response.data.res.aptNum
                        // 城市
                        this.municipality = response.data.res.municipality
                        // 省份
                        this.county = response.data.res.county
                        // 邮政编码
                        this.zip = response.data.res.zip
                        // 在市天数取前10位
                        this.timestampSql = (response.data.res.timestampSql).substring(0, 10)
                        // 卧室
                        this.br = response.data.res.br
                        // Den额外房间 (如果没有不显示)
                        if (response.data.res.brPlus != '0') {
                            this.brPlus = "+" + response.data.res.brPlus
                        }
                        // 厨房
                        this.numKit = response.data.res.numKit
                        // 卫生间数量
                        this.bathTot = response.data.res.bathTot
                        // 车位数量(取第一位)
                        this.totParkSpcs = (response.data.res.totParkSpcs).substring(0, 1)
                        // 面积
                        if (response.data.res.sqft != 'null') {
                            this.sqft = response.data.res.sqft
                        } else {
                            this.sqft = '未知'
                        }

                        // ID
                        this.mlNum = response.data.res.mlNum
                        // 状态
                        // 产权
                        this.typeOwn1Out = response.data.res.typeOwn1Out
                        // 类型
                        this.style = response.data.res.style
                        // 房龄
                        if (response.data.res.yrBuilt != 'null') {
                            this.assYear = response.data.res.yrBuilt
                        } else {
                            this.assYear = '未知'
                        }
                        // 交接日期
                        if (response.data.res.occ = 'Immediate') {
                            this.occ = '即刻入住'
                        } else {
                            this.occ = response.data.res.occ
                        }
                        // 挂盘公司
                        this.rltr = response.data.res.rltr

                        // 供暖方式
                        this.fuel = response.data.resDetail.fuel
                        // 供暖类型
                        this.heating = response.data.resDetail.heating
                        // 设施
                        if (response.data.resDetail.propFeat1Out == 'null') {
                            this.prop_feat_out = '未知'
                        } else {
                            this.prop_feat_out = response.data.resDetail.propFeat1Out + " " + response.data.resDetail.propFeat2Out +
                                " " + response.data.resDetail.propFeat3Out + " " + response.data.resDetail.propFeat4Out +
                                " " + response.data.resDetail.propFeat5Out + " " + response.data.resDetail.propFeat6Out
                        }


                        // 电视
                        this.cable = response.data.res.cable
                        // 附注
                        this.adText = response.data.res.adText
                        this.bsmt1Out = response.data.resDetail.bsmt1Out
                        // 税
                        this.tax = "$ " + Number(response.data.res.taxes).toLocaleString()
                        // 社区
                        this.community = response.data.res.community

                        for (var i = 0; i <= parseInt(this.urlSize); i++) {
                            this.imgSrc[i] = "http://www.breadrent.com/ResMedia/" + this.mlNum + "/" + (i) + ".jpeg"
                        }

                    } else {
                        // Error handle
                        this.lpDol = '数据获取错误'
                    }

                })
        },
        pdfBtn() {
            htmlToPdf.getPdf(this.htmlTitle);
        },
    },
    mounted() {
        // this.fetchDetailData(this.type, this.id)
        this.fetchDetailData()

    },
    // props: [type, id],
    data() {
        return {
            condo: false,
            res: false,
            addr: '',
            ac: '',
            aptNum: '',
            area: '',
            areaCode: '',
            assYear: '',
            br: '',
            brPlus: '',
            community: '',
            communityCode: '',
            ensLndry: '',
            condoExp: '',
            furnished: '',
            idxDt: '',
            rltr: '',
            lpDol: '',
            locker: '',
            mlNum: '',
            municipality: '',
            municipalityCode: '',
            prkgInc: '',
            pets: '',
            pixUpdt: '',
            occ: '',
            zip: '',
            county: '',
            adText: '',
            sr: '',
            status: '',
            stNum: '',
            st: '',
            timestampSql: '',
            totParkSpcs: '',
            unitNum: '',
            tourUrl: '',
            bathTot: '',
            waterInc: '',
            typeOwn1Out: '',
            style: '',
            hydroInc: '',
            allInc: '',
            urlSize: '',
            sqft: '',
            stories: '',
            lng: '',
            lat: '',
            maint: '',
            condoCorp: '',
            corpNum: '',
            constr1Out: '',
            constr2Out: '',
            fuel: '',
            heating: '',
            prop_feat_out: '',
            bsmt1Out: '',
            cable: '',
            crossSt: '',


            imgSrc: [],


            htmlTitle: '房源详情PDF',
        }
    },

}
</script>



<style>
body {
    padding: 20px;
}

.text-wrapper {
    white-space: pre-line;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: #642afb;
    color: white;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}

.carousel__prev,
.carousel__next {
    background-color: #fff7;
}
</style>
<style scoped>
.carousel__slide {
    padding: 5px;
}

.carousel__viewport {
    perspective: 2000px;
}

.carousel__track {
    transform-style: preserve-3d;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active~.carousel__slide {
    transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
}
</style>