<template>
    <NavBar />
    <!-- <MapListSelectedBar class="selected-bar-top"/> -->
    <v-row class="selected-bar-top">
        <v-col
            cols="3"
            >
            <v-card
                color="#ff000000"
                flat
    
                >
                <v-card-text>
                    <v-text-field
                        :loading="loading"
                        density="compact"
                        variant="solo"
                        label="输入城市，地址，楼盘，开发商"
                        append-inner-icon="mdi-magnify"
                        single-line
                        hide-details
                        @click:append-inner="onClick"
                        >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="8">
            <v-row>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteWuye"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="物业类型"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="类型"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="价格"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="卧室数量"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                        class="selected-select"
                        :items="selecteState"
                        item-title="state"
                        item-value="value"
                        return-object
                        single-line
                        height=50
                        outline
                        label="浴室数量"
                        density="compact"
                        bg-color="#ffffff"
                        >
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        depressed
                        color="#ffffff"
                        class="selected-switch-btn"
                        @click="this.updateMapData()"
                        >
                        更多筛选
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="1">
            <v-btn
                depressed
                color="red-darken-2"
                class="selected-switch-btn"
                @click="showMap = !showMap"
                >
                {{ showMap ? switchBtnText1 : switchBtnText2 }}
            </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col :cols="showMap? listCol : listColFull">
            <v-card class="selected-card" border flat>
                <v-row no-gutters>
                    <v-col
                        v-for="house, i in houses"
                        :key="i"
                        cols="12"
                        :sm="showMap? listSm : listSmFull"
                        >
                        <MapListHouseCard :house="house"/>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col v-show="showMap" cols="6">
            <v-card class="selected-card">
                <div id="map"></div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
// pk.eyJ1IjoibGlua2RvdmFraW4iLCJhIjoiY2xhaHgyZHJyMDMxazNubDdzZ3d6MzdkOCJ9.A4OA6XXM7bn3kpXdQ8hBcw
    import NavBar from '@/components/Navbar.vue'
    import MapListSelectedBar from '@/components/MapListSelectedBar'
    import mapboxgl from 'mapbox-gl'
    import { onMounted } from "vue";
    import MapListHouseCard from '@/components/MapListHouseCard.vue';
    
    export default {
        name: 'MapList',
        components: {
            NavBar,
            MapListSelectedBar,
            MapListHouseCard
        },
        methods: {
            createMap() {
                mapboxgl.accessToken =
                    "pk.eyJ1IjoibGlua2RvdmFraW4iLCJhIjoiY2xhaHgyZHJyMDMxazNubDdzZ3d6MzdkOCJ9.A4OA6XXM7bn3kpXdQ8hBcw";
                this.map = new mapboxgl.Map({
                    container: "map",
                    style: "mapbox://styles/mapbox/streets-v12",
                    center: [-79.386351051501805, 43.697831265018799],
                    zoom: 11
                });
                this.map.on('load', () => {
                    this.map.addSource('places', this.geoJson);
                    this.map.addLayer({
                        'id': 'places',
                        'type': 'circle',
                        'source': 'places',
                        'paint': {
                            'circle-color': '#A7121D',
                            'circle-radius': 8,
                            'circle-stroke-width': 2,
                            'circle-stroke-color': '#ffffff'
                        }
                    });
                    const popup = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false
                    });
                    this.map.on('mouseenter', 'places', (e) => {
                        this.map.getCanvas().style.cursor = 'pointer';
                        
                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const description = e.features[0].properties.description;
                        
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
                    });
                    this.map.on('mouseleave', 'places', () => {
                        this.map.getCanvas().style.cursor = '';
                        popup.remove();
                    });
                    this.map.on('moveend', () => {
                        console.log(this.map.getBounds())
                        this.updateMapData()
                    });
                });
                this.map.addControl(new mapboxgl.NavigationControl());
            },
            fetchMapData() {
                fetch('http://localhost:8100/api/bread/landing/map', {
                    method: 'get',
                    headers: {'Content-type': 'application/json'},
                })
                .then(res => res.json())
                .then((response) => {
                    this.geoJson = response.data
                    console.log( this.geoJson )
                    // const geojsonSource = this.map.getSource('places')
                    // geojsonSource.setData(this.geoJson)
                    this.createMap()
                })
            },
            updateMapData() {
                fetch('http://localhost:8100/api/bread/landing/map', {
                    method: 'get',
                    headers: {'Content-type': 'application/json'},
                })
                .then(res => res.json())
                .then((response) => {
                    this.geoJson = response.data
                    console.log( this.geoJson )
                    const bounds = this.map.getBounds()
                    console.log(bounds)
                    const geojsonSource = this.map.getSource('places')
                    geojsonSource.setData({
                    "type": "FeatureCollection",
                    "features": [{
                    "type": "Feature",
                    "properties": {"name": "Null Island"},
                    "geometry": {
                    "type": "Point",
                    "coordinates": [ -79.3921129, 43.6450134 ]
                    }
                    }]
                    })
                    // this.createMap()
                })
            }
        },

        mounted() {
           this.fetchMapData()
        },
      
        data() {
            return {
                map: {},
                geoJson: '{}',
                selecteWuye: [
                    {
                        state: '物业类型1',
                        value: '1',
                    },
                    {
                        state: '物业类型2',
                        value: '2',
                    },
                ],
                selecteState: [
                    {
                        state: '出售',
                        value: '1',
                    },
                    {
                        state: '出租',
                        value: '2',
                    },
                    {
                        state: '售出',
                        value: '3',
                    },
                    {
                        state: '租出',
                        value: '4',
                    },
                ],
                showMap: true,
                switchBtnText1: '列表',
                switchBtnText2: '地图',
                listCol: 6,
                listColFull: 12,
                listSm: 4,
                listSmFull: 2,
                houses:[
                    {
                            mlNum: 'C5625246',
                            addr: '115 Mcmahon Dr',
                            lpDol: '645,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/4.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/5.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/6.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/7.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5625246/8.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'S5598774',
                            addr: '58 Lakeside Terr',
                            lpDol: '549,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/S5598774/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5579580',
                            addr: '1 Avondale Ave',
                            lpDol: '788,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5579580/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5624763',
                            addr: '455 Front St E',
                            lpDol: '599,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5624763/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5626010',
                            addr: '15 Stafford St',
                            lpDol: '19,650',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626010/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'C5626433',
                            addr: '60 Tannery Rd',
                            lpDol: '759,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/C5626433/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'W5568364',
                            addr: '320 Dixon Rd',
                            lpDol: '424,900',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/W5568364/4.jpeg',
                                },
                            ]
                    }, 
                    {
                            mlNum: 'E5573103',
                            addr: '1359 Neilson Rd',
                            lpDol: '695,000',
                            items: [
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/1.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/2.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/3.jpeg',
                                },
                                {
                                    src: 'http://www.breadrent.com/CondoMedia/E5573103/4.jpeg',
                                },
                            ]
                    }, 
                ],
            }
        },
    }
</script>

<style>
#map {
  height: 80vh;
}
.selected-bar-top {
    margin-top: 3px;
}
.selected-card {
    height: 80vh !important;
    margin-left: 10px;
    overflow-y: auto;
}
.selected-select {
    margin-top: 17px;
}
</style>