<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <h1>加拿大找房</h1>
                    <br>
                    <h1>租客信息登记表</h1>
                    <br>

                    <v-text-field class="mx-auto" clearable label="姓名 *" variant="underlined" placeholder="请输入姓名"
                        :rules="[
                            v => !!v || '姓名不能为空'
                        ]"></v-text-field>
                    <v-text-field class="mx-auto" clearable label="室友姓名 " variant="underlined"
                        placeholder="请输入姓名"></v-text-field>
                    <v-text-field class="mx-auto" clearable label="邮箱 *" variant="underlined" placeholder="邮箱"
                        type="email" :rules="[
                            v => !!v || '邮箱不能为空'
                        ]"></v-text-field>
                    <v-text-field class="mx-auto" clearable label="电话" variant="underlined"
                        placeholder="请输入电话"></v-text-field>
                    <div>请选择入住日期：</div>
                    <br>
                    <Datepicker v-model="moveInDate" placeholder="请点击选择" required :enable-time-picker="false"
                        teleport-center></Datepicker>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <div>请选择睡房数：</div>
                    <br>
                
                    <v-radio-group inline>
                        <v-radio label="1" value="1"></v-radio>
                        <v-radio label="2" value="2"></v-radio>
                        <v-radio label="3" value="3"></v-radio>
                        <v-radio label="Studio" value="0"></v-radio>
                        <v-radio label="1+Den" value="1+1"></v-radio>
                    </v-radio-group>
                    <v-divider></v-divider>
                    <br>
                    <div>租房预算</div>
                    <v-slider v-model="price" :min="0" :max="4500" :step="100" thumb-label show-ticks="always"
                        :ticks="tickLabels"></v-slider>

                    <br>
                    <v-divider></v-divider>
                    <br>
                    <div>就读学校：</div>
                    <v-select label="请选择" :items="['多伦多大学', 'OCAD']" variant="underlined"></v-select>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <div>个人情况-选择</div>
                    <v-select chips :items="['有信用报告', '工作证明', '2个月内工资单流水', '银行流水']" multiple
                        variant="underlined"></v-select>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <div>如果没有是否可以预付6个月房租</div>
                    <v-radio-group>
                        <v-radio label="6个月" value="6Months"></v-radio>
                        <v-radio label="12个月" value="12Months"></v-radio>
                        <v-radio label="坚决不能预付" value="2Months"></v-radio>
                    </v-radio-group>
                    <v-divider></v-divider>
                    <br>
                    <div>可以本人到场看房</div>
                    <v-radio-group>
                        <v-radio label="不能亲自到场看房" value="noShowing"></v-radio>
                    </v-radio-group>
                    <v-divider></v-divider>
                    <br>
                    <v-text-field class="mx-auto" clearable label="紧急情况联系人1电话 " variant="underlined"
                        placeholder="父亲或母亲联系电话"></v-text-field>
                    <v-divider></v-divider>
                    <br>
                    <v-text-field class="mx-auto" clearable label="紧急情况联系人2电话 " variant="underlined"
                        placeholder="父亲或母亲联系电话"></v-text-field>
                    <v-textarea clearable rows="1" auto-grow label="留言" variant="underlined"></v-textarea>
                    <v-file-input clearable label="请上传证明文件" variant="underlined"></v-file-input>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
        </v-card-text>
    </v-card>

</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'FormRental',
    components: { Datepicker },
    data() {
        return {
            bedroom: '',
            moveInDate: null,
            price: 0,
            tickLabels: {
                0: '0',

                1000: '1000',

                2000: '2000',
              
                3000: '3000',
                
                4000: '4000',
            },

        }
    }
}
</script>