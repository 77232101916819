<template>
    <v-row>
        <v-col
            cols="4"
            >
            <v-card
                class="mx-auto"
                color="#ff000000"
                flat
                max-width="400"
                >
                <v-card-text>
                    <v-text-field
                        :loading="loading"
                        density="compact"
                        variant="solo"
                        label="输入城市，地址，楼盘，开发商"
                        append-inner-icon="mdi-magnify"
                        single-line
                        hide-details
                        @click:append-inner="onClick"
                        >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="2">
            <v-btn
                depressed
                color="red-darken-2"
                @click="showMap = !showMap"
                >
                {{ showMap ? switchBtnText1 : switchBtnText2 }}
            </v-btn>
        </v-col>
    </v-row>
 
</template>

<script>

    export default {
        data() {
            return {
                showMap: true,
                switchBtnText1: '列表',
                switchBtnText2: '地图'
            }
        }
    }
</script>

<style>
.selected-bar-input {
    height: 80px;
    margin-left: 2px;
    max-width: 200px;
}
.selected-bar-search-btn {
    height: 80px;
    vertical-align: bottom;
}
.selected-switch-btn {
    margin-top: 18px;
}
</style>