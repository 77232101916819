import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import LouhuaTest from '@/views/LouhuaTest'
import MapList from '@/views/MapList'
import CondoDetail from '@/views/CondoDetail'
import 'mapbox-gl/dist/mapbox-gl.css';
import FormRental from '@/views/FormRental'
import Builder from '@/views/Builder'
import Test from '@/views/Test'
import Share from '@/views/ShareCondoDetail'
import En from '@/views/CondoDetailEn'

import Carousel3d from 'vue-carousel-3d';


loadFonts()

const routes = [
  {
    path: '/',
    component: Home // 首页
  },
  {
    path: '/louhua',
    component: LouhuaTest // 楼花
  },
  {
    path: '/maplist',
    component: MapList // 地图列表
  },
  {
    path: '/condo/detail',
    component: CondoDetail // 公寓详情
  },
  {
    path: '/formrental',
    component: FormRental // 租客需求Form
  },
  {
    path: '/builder',
    component: Builder // 建筑商
  },
  {
    path: '/test',
    component: Test // 测试
  },
  {
    path: '/share',
    component: Share // 详情分享页面
  },
  {
    path: '/detail/en',
    component: En // 详情分享页面
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes, 
})

createApp(App)
  .use(router)
  .use(vuetify)
  .use(Carousel3d)
  .mount('#app')
