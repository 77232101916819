<template>
     <!-- 开发商简介 -->
  <div>
    <v-container>
      <v-row align="start" style="height: 120px;">
        <v-col cols="2">
        </v-col>

        <v-col cols="2">
          <h2>Altree Developments</h2>
          <h3>
            <span>6477155711</span>|<span>info@altreedev.com</span>
          </h3>
        </v-col>
        <v-col cols="2">
          <h3><b>2828 Bathurst St,</b></h3>
          <h3>Suite 300, </h3>
          <h3>Toronto, ON, M6B 3A7</h3>

        </v-col>
        <v-col cols="2">
          <h3>Public Sales: <span style="color:grey; font-weight: lighter;">4</span></h3>
          <h3>VIP Sales: <span style="color:grey; font-weight: lighter;">0</span></h3>
          <h3>Planning: <span style="color:grey; font-weight: lighter;">7</span></h3>
        </v-col>
        <v-col cols="2">
          <h3>Coming Soon: <span style="color:grey; font-weight: lighter;">0</span></h3>
          <h3>Sold Out: <span style="color:grey; font-weight: lighter;">0</span></h3>
        </v-col>

        <v-col cols="2">
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- 分割线 -->
  <v-divider></v-divider>
  <br>

  <v-row align="start">
    <v-col cols="2">
    </v-col>

    <v-col cols="4">
      <v-carousel cycle>
        <v-carousel-item
          src="https://condonow.com/Highland-Commons-Condos-2/images/Highland-Commons-Condos-2-Exterior-View-at-Dusk-1-v68.jpg"></v-carousel-item>

        <v-carousel-item
          src="https://condonow.com/Forest-Hill-Private-Residences/images/Forest-Hill-Private-Residences-Building-Exterior-and-Street-View-1-v278.jpg"></v-carousel-item>

        <v-carousel-item
          src="https://condonow.com/Birchaus-Condos/images/Birchaus-Condos-by-Altree-Development-1-v13.jpg"></v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col cols="3" offset="0.2">
      <h3><b>DESCRIPTION</b></h3>
      <p style="font-size:18px;">Founded by Zev Mandelbaum, Altree stems from a long lineage of multi-generational
        expertise in bringing to fruition landmark projects and iconic communities throughout Toronto and the GTA. Zev
        is one of the visionary co-founders behind Marlin Spring, and a third-generation developer with deep roots in
        the industry that date back to the 1950s through H&R and Lanterra Developments.</p>
      <p style="font-size:18px;">Altree is committed to challenging the status quo and breaking new ground in creating
        inspirational residential, commercial, and retail projects with a compelling offering that creates significant
        value for customers, investors, and community stakeholders.</p>
      <p style="font-size:18px;">As a full-service development company, Altree provides unmatched insight, expertise,
        and service throughout the full spectrum of development – from strategic acquisition to planning, construction,
        sales and marketing, finance structuring, tax, and legal processes. By consistently going above and beyond
        expectations and identifying opportunities to enhance neighbourhoods and connect communities, Altree is
        unparalleled in the creation of iconic projects that branch out from the ordinary.</p>

    </v-col>


    <v-col cols="3">
    </v-col>
  </v-row>

  <!-- 分割线 -->
  <br>
  <v-divider></v-divider>
  <br>

  <div style="text-align: center;">
    <h2><span style="color: grey; font-weight: lighter;">Displaying: </span>
      11 Developments, 77 Available Floor Plans</h2>
  </div>

  <v-row>
    <v-col cols="2">
    </v-col>
    <v-col cols="8">
      <!-- tab -->
      <v-card>
        <v-tabs v-model="tab">
          <v-tab value="list">List View</v-tab>
          <v-tab value="map">Map View</v-tab>

        </v-tabs>
        <v-card-text>
          <v-window v-model="tab">
            <v-window-item value="list">
              <v-list :items="items" item-props lines="eight">
                <template v-slot:subtitle="{ subtitle }">
                  <div v-html="subtitle"></div>
                </template>
              </v-list>
            </v-window-item>
            <v-window-item value="map">
              map
            </v-window-item>


          </v-window>
        </v-card-text>
      </v-card>

      <!-- <v-list :items="items" item-props lines="eight">
        <template v-slot:subtitle="{ subtitle }">
          <div v-html="subtitle"></div>
        </template>
      </v-list> -->

    </v-col>
    <v-col cols="2">
    </v-col>

  </v-row>

</template>

<script>
export default {
  data: () => ({
    items: [
      {
        prependAvatar: 'https://condonow.com/36-Zorra-Condos/images/Tower-View-of-36-Zorra-Condos-Early-Rendering-26-v117-full.jpg',
        title: 'Lansing Square Condos',
        subtitle: `<span class="text-primary">Almadev</span> <br> 
      Victoria Park Ave & Sheppard Ave E, Toronto 
      <br>
      <br>
      <span style="font-weight:bold;">Coming Feb,2023 <span style="float:right;"">Coming Soon</span></span> 
      <br>
      `,
      },
      { type: 'divider', inset: true },
      {
        prependAvatar: 'https://condonow.com/Centricity-Condos/images/Centricity-Condos-thumbnail-v117.jpg',
        title: 'Centricity Condos',
        subtitle: `<span class="text-primary">Graywood Developments Ltd.</span> <br> 
      Church St & Dundas St E, Toronto 
      <br>
      <br>
      <span style="font-weight:bold;">Coming Q1,2023 <span style="float:right;"">VIP Sales</span></span> 
      <br>
      `,
      },
    ],
  }),
}

</script>
